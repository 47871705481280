import React from 'react';
import { useTranslation } from 'react-i18next';
import { Itens, LinkPrincipalStyled, LinkStyled } from './styles';
import { useParams } from 'react-router-dom';

const EMerkator = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Itens>
      <LinkPrincipalStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkator')}
      </LinkPrincipalStyled>

      <LinkStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkatorTalks')}
      </LinkStyled>
      <LinkStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkatorBlog')}
      </LinkStyled>
      <LinkStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkatorTV')}
      </LinkStyled>
      <LinkStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkatorCast')}
      </LinkStyled>
      <LinkStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkatorPush')}
      </LinkStyled>
    </Itens>
  );
};

export default EMerkator;
