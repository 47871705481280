export default {
  title: 'quarentaGraus',

  colors: {
    primary: '#C11523',
    secondary: '#E94E1D',
    tertiary: '#f5a3aa',
    text: '#A6A4A7',
    textInBg: '#fff',
    borders: '#707070',
    borderBotaoEMerkator: '#ffffff',
    boxShadow: '#000000',
  },
  transparentColors: {
    primary: 'rgba(193,21,35,0.5)',
    secondary: 'rgba(233,78,29,0.7)',
  },
};
