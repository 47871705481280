import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Conteudo,
  Box,
  ImageSvg,
  LinkPlanta,
} from './styles';
import { useTranslation } from 'react-i18next';
import blNewsService from '../../../services/blNewsService';

function SecaoPlantaDaFeira({ idPage, imagemPlanta }) {
  const { t } = useTranslation();
  const secaoId = t('slugs.plantaBaixa');
  const { getPaginas } = blNewsService();
  const [dadosPagina, setDadosPagina] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { items } = await getPaginas(idPage);
      if (items && items.length > 0) {
        setDadosPagina(items[0]);
      }

      setIsLoading(false);
    })();
  }, []);

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>{isLoading ? '' : dadosPagina.title}</SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <Row>
        <Col md="12">
          {isLoading ? (
            ''
          ) : dadosPagina.image ? (
            <>
              <LinkPlanta
                href="http://paineldoexpositor.com.br/uploads/media/media_627abe40f38521_46608704.pdf"
                target="_blank"
              >
                Clique aqui para baixar a planta em PDF
              </LinkPlanta>
              <Box>
                <ImageSvg
                  src={dadosPagina.image}
                  alt="Planta baixa SICC - 2022"
                />
              </Box>
            </>
          ) : (
            <Conteudo
              dangerouslySetInnerHTML={{
                __html: isLoading ? '' : dadosPagina.content,
              }}
            />
          )}
        </Col>
      </Row>
    </Secao>
  );
}

SecaoPlantaDaFeira.propTypes = {
  idPage: PropTypes.any,
  imagemPlanta: PropTypes.any,
};

export default SecaoPlantaDaFeira;
