import React from 'react';
import SecaoCidadeExposicaoShared from '@alweb-merkator/shared/pages/PaginaInicial/SecaoCidadeExposicao';
import { useTranslation } from 'react-i18next';

function SecaoCidadeExposicao(props) {
  const { t } = useTranslation();
  return (
    <SecaoCidadeExposicaoShared slugKey={t('slugs.joaoPessoa')} {...props} />
  );
}

export default SecaoCidadeExposicao;
