import styled from 'styled-components';
import Banner from '../../../components/Banner';

export { Col, Row } from 'reactstrap';
export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../../components/Secao';

export const Conteudo = styled.div``;

export const ImagemResponsiva = styled(Banner)`
  display: block;
  margin-left: auto;
`;

export const Box = styled.div`
  padding: 0px;
  margin-top: 15px;
  border: 1px solid ${(props) => props.theme.colors.borders};
`;

export const TextoAgenciaTurismoOficial = styled.span`
  font-style: italic;
`;

export const ImageSvg = styled.img`
  max-width: 100%;
`;

export const LinkPlanta = styled.a`
  color: #A6A4A7;
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 30px;
`;
