import styled from 'styled-components';
import BannerInternas from '../../../components/BannerInternas';

export { Col, Row } from 'reactstrap';
export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../../components/Secao';

export const Conteudo = styled.div``;

export const ImagemResponsiva = styled(BannerInternas)`
  display: block;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin-left: auto;
  }
`;

export const Box = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    position: absolute;
    display: inline-block;
    top: calc(50% - 88.5px);
    right: 15px;
    text-align: right;
    -ms-transform: translate(0, calc(-50% + 88.5px));
    transform: translate(0, calc(-50% + 88.5px));
  }
`;

export const TextoAgenciaTurismoOficial = styled.span`
  font-style: italic;
`;
