import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Tradutor } from '../../../components/I18n';

import {
  // Col,
  // Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Conteudo,
  // Botao,
} from './styles';

function SecaoExpositores() {
  const { t } = useTranslation();
  const secaoId = t('slugs.expositores');
  // const { lang } = useParams();

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path="paginas.inicial.secaoExpositores.titulo" />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <Conteudo
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: t('paginas.inicial.secaoExpositores.subTituloBreve'),
        }}
      />
      {/* <Row>
        <Col md="4">
          <Botao
            to={`/${lang}/${t('slugs.expositores')}/${t(
              'slugs.expositoresCategoriaFeminino'
            )}`}
          >
            Feminino
          </Botao>
        </Col>
        <Col md="4">
          <Botao
            to={`/${lang}/${t('slugs.expositores')}/${t(
              'slugs.expositoresCategoriaMasculino'
            )}`}
          >
            Masculino
          </Botao>
        </Col>
        <Col md="4">
          <Botao
            to={`/${lang}/${t('slugs.expositores')}/${t(
              'slugs.expositoresCategoriaInfantil'
            )}`}
          >
            Infantil
          </Botao>
        </Col>
        <Col md="4">
          <Botao
            to={`/${lang}/${t('slugs.expositores')}/${t(
              'slugs.expositoresCategoriaBolsasAcessorios'
            )}`}
          >
            Bolsas, acessórios e artefatos de couro
          </Botao>
        </Col>
        <Col md="4">
          <Botao
            to={`/${lang}/${t('slugs.expositores')}/${t(
              'slugs.expositoresCategoriaEsportivos'
            )}`}
          >
            Esportivos
          </Botao>
        </Col>
        <Col md="4">
          <Botao to={`/${lang}/${t('slugs.expositores')}`}>
            <Tradutor path="paginas.expositores.botaoCategoria.listaCompleta" />
          </Botao>
        </Col>
      </Row> */}
    </Secao>
  );
}

export default SecaoExpositores;
