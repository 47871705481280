import styled, { createGlobalStyle } from 'styled-components';
import Banner from '../../components/Banner';
import { SecaoCabecalho as CompSecaoCabecalho } from '../../components/Secao';

export { Col, Row } from 'reactstrap';
export { Tradutor } from '../../components/I18n';
export { Secao, SecaoBotaoVoltar, SecaoTitulo } from '../../components/Secao';

export const GlobalStyles = createGlobalStyle`
  .pagina-expositores {
    ul {
      box-sizing: border-box;
      list-style: none;
      padding: 0;
      margin-bottom: 0;
    }

    ul > li {
      margin-bottom: 15px;
    }

    ul > li:last-of-type {
      margin-bottom: 0;
    }

    .expositores {
      overflow-y: scroll;
      box-sizing: border-box;
      max-height: 396px;

      @media (min-width: 992px) {
        max-height: 447px;
      }

      @media (min-width: 1200px) {
        max-height: 477px;
      }
    }

    .expositor {
      border-bottom: 2px solid ${(props) => props.theme.colors.primary};
      padding-bottom: 20px;

      p {
        margin: 0;
      }

      p:first-of-type {
        color: ${(props) => props.theme.colors.primary};
        font-weight: 700;
      }
    }

    .expositores::-webkit-scrollbar {
      width: 20px;
    }

    .expositores::-webkit-scrollbar-track {
      background: #C5BAC1;
      border-radius: 0;
    }
    
    .expositores::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.primary};
      border-radius: 0;
    }

    .expositores::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const SecaoCabecalho = styled(CompSecaoCabecalho)`
  margin-bottom: 4rem;
`;

export const Botao = styled.button.attrs(({ className }) => ({
  className: `btn btn-primary w-100 ${className}`,
}))`
  font-size: 1.5rem;
  padding: 10px 0;
`;

export const Conteudo = styled.div``;

export const ImagemResponsiva = styled(Banner)`
  display: block;
  margin-left: auto;
`;

export const Box = styled.div`
  text-align: right;
  position: absolute;
  display: inline-block;
  top: calc(50% - 88.5px);
  right: 15px;
  -ms-transform: translate(0, calc(-50% + 88.5px));
  transform: translate(0, calc(-50% + 88.5px));
`;

export const TextoAgenciaTurismoOficial = styled.span`
  font-style: italic;
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 477px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const InputSearch = styled.input`
  background-color: #d1d1d1;
  color: #707070;
  border: 0;
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
`;
