import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import blNewsService from '../../../services/blNewsService';
import {
  BannerInternas,
  Conteudo,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Tradutor,
} from './styles';

const SecaoParceiros = ({ idPage }) => {
  const { t } = useTranslation();
  const secaoId = t('slugs.parceiros');
  const { getPaginas, switchSizeImage } = blNewsService();
  const [parceiros, setParceiros] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = async () => {
    await getPaginas(idPage)
      .then(({ items }) => (items && items.length ? items[0] : {}))
      .then((dado) => {
        setParceiros(dado);
        setIsLoading(true);
      });
  };

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>
          <Tradutor path={isLoading ? parceiros.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <BannerInternas
        src={isLoading ? switchSizeImage(parceiros.image, 'lg') : ''}
        alt={isLoading ? parceiros.image_description : ''}
        className="mb-4 mt-30"
      />
      <Conteudo
        dangerouslySetInnerHTML={{
          __html: isLoading ? parceiros.content : '',
        }}
      />
    </Secao>
  );
};

SecaoParceiros.propTypes = {
  idPage: PropTypes.any,
};

export default SecaoParceiros;
