import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Tradutor } from '../../../components/I18n';

import {
  Col,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  ImagemResponsiva,
  Conteudo,
  TextoAgenciaTurismoOficial,
  Box,
} from './styles';
import blNewsService from '../../../services/blNewsService';

function SecaoAgenciaDeTurismo({ idPage }) {
  const { t } = useTranslation();
  const { lang } = useParams();
  const { getPaginas } = blNewsService();
  const secaoId = t('slugs.agenciaDeTurismo');
  const [agencia, setAgencia] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const _handleGetGramado = useCallback(async () => {
    await getPaginas(idPage).then(({ items }) => {
      const dado = items && items.length ? items[0] : {};
      setAgencia(dado);
      setIsLoading(true);
    });
  }, [idPage, lang]);

  useEffect(() => {
    _handleGetGramado();
    return function cleanup() {};
  }, [_handleGetGramado]);

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4 mb-30">
        <SecaoTitulo>
          <Tradutor path={isLoading ? agencia.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <Row>
        <Col md="6">
          <Conteudo
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: isLoading ? agencia.content : '',
            }}
          />
        </Col>
        <Col md="6">
          <Box>
            <TextoAgenciaTurismoOficial>
              <Tradutor path="paginas.inicial.secaoAgenciaDeTurismo.subTitulo" />
            </TextoAgenciaTurismoOficial>
            <ImagemResponsiva
              src={isLoading ? agencia.image : ''}
              alt={isLoading ? agencia.image_description : ''}
            />
          </Box>
        </Col>
      </Row>
    </Secao>
  );
}

SecaoAgenciaDeTurismo.propTypes = {
  idPage: PropTypes.any,
};

export default SecaoAgenciaDeTurismo;
