import styled from 'styled-components';
import { SecaoMenuLista as CompSecaoMenuLista } from '../../components/Secao';
export { Tradutor } from '../../components/I18n';
export { default as ImageCover } from '../../components/ImageCover';

export { Link } from 'react-router-dom';

export const SecaoMenuLista = styled(CompSecaoMenuLista)`
  justify-content: flex-end;
`;

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuItem,
  SecaoBotaoVoltar,
} from '../../components/Secao';

export const ReloadPosts = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TextReload = styled.p`
  display: flex;
  width: 20%;
  flex-direction: center;
  align-items: center;
  border-color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textInBg};
  flex-direction: column;
  padding: 5px;
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Galeria = styled.div`
  .galery_container {
    margin-bottom: 15px;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }

  .galery_album_card {
    width: calc((100% - 15px * 2) / 3);
    height: 100px;
    float: left;
    margin-right: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .galery_card {
    width: calc((100% - 15px * 2) / 2);
    white-space: normal;
    height: 100px;
    text-align: center;
    display: inline-block;
    margin-right: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .galery_album_card {
      width: calc((100% - 15px * 4) / 5);
      height: 115px;
    }
    .galery_card {
      width: calc((100% - 15px * 4) / 4);
      height: 115px;
    }
  }

  @media screen and (min-width: 992px) {
    .galery_album_card {
      width: calc((100% - 15px * 4) / 5);
      height: 120px;
    }
    .galery_card {
      width: calc((100% - 15px * 4) / 4);
      height: 120px;
    }
  }

  @media screen and (min-width: 1200px) {
    .galery_album_card {
      width: calc((100% - 15px * 5) / 6);
      height: 130px;
    }
    .galery_card {
      width: calc((100% - 15px * 5) / 5);
      height: 130px;
    }
  }

  .cards {
    width: auto;
    white-space: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background: #c5bac1;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.primary};
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.primary};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .galery_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .galery_overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.transparentColors.secondary};
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
  }

  .galery_card:hover .galery_overlay {
    height: 100%;
  }

  .galery_text {
    color: ${(props) => props.theme.colors.textInBg};
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
