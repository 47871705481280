import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tradutor } from '../../../components/I18n';
import blNewsService from '../../../services/blNewsService';
import {
  BannerInternas,
  Col,
  Conteudo,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Iframe,
} from './styles';

const SecaoLocalExposicao = ({
  idPage,
  slugKey,
  fullMap = false,
  googleMapsUrl = '',
}) => {
  const { t } = useTranslation();
  const secaoId = t(slugKey);
  const { getPaginas, switchSizeImage } = blNewsService();
  const [model, setModel] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = async () => {
    await getPaginas(idPage)
      .then(({ items }) => (items && items.length ? items[0] : {}))
      .then((dado) => {
        setModel(dado);
        setIsLoading(true);
      });
  };

  const renderGoogleMapsIframe = (url) => (
    <Iframe src={url} style={{ border: 0 }} allowFullScreen="" loading="lazy" />
  );

  const fullCols = useMemo(() => fullMap || !googleMapsUrl, [
    googleMapsUrl,
    fullMap,
  ]);

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? model.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <SecaoSubTitulo className="mb-4">
        <Tradutor path={isLoading ? model.description : ''} />
      </SecaoSubTitulo>

      <BannerInternas
        src={isLoading ? switchSizeImage(model.image, 'lg') : ''}
        alt={isLoading ? model.image_description : ''}
        className="mb-4 mt-30"
      />

      <Row>
        <Col md={fullCols ? '12' : '6'}>
          <Conteudo
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: isLoading ? model.content : '',
            }}
          />
        </Col>
        <Col md={fullCols ? '12' : '6'}>
          {googleMapsUrl ? renderGoogleMapsIframe(googleMapsUrl) : null}
        </Col>
      </Row>
    </Secao>
  );
};

SecaoLocalExposicao.propTypes = {
  idPage: PropTypes.any,
  slugKey: PropTypes.string,
  googleMapsUrl: PropTypes.string,
  fullMap: PropTypes.bool,
};

export default SecaoLocalExposicao;
