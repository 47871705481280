import React from 'react';
import ThemeProviderShared from '@alweb-merkator/shared/providers/ThemeProvider';

import quarentaGrausTheme from '../styles/themes/quarentaGraus';

const ThemeProvider = (props) => (
  <ThemeProviderShared currentTheme={quarentaGrausTheme} {...props} />
);

export default ThemeProvider;
