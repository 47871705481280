import React from 'react';
import { useTranslation } from 'react-i18next';

import Head from '@alweb-merkator/shared/components/Head';
import {
  Styles,
  Secao,
  Tradutor,
  SecaoMenu,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuNav,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoBotaoVoltar,
} from './styles';

const Traslado = () => {
  const { t } = useTranslation();

  return (
    <div className="pagina-traslado">
      <Styles />
      <Head
        title={t('paginas.traslado.titulo')}
        description={t('paginas.traslado.titulo')}
      />
      <Secao className="mb-4">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <Tradutor path="paginas.traslado.titulo" />
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-12">
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.conteudo'),
              }}
            ></div>
          </div>
        </div>
      </Secao>
    </div>
  );
};

export default Traslado;
