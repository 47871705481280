import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tradutor } from '../../../components/I18n';
import blNewsService from '../../../services/blNewsService';
import {
  BannerInternas,
  Conteudo,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from './styles';

const SecaoCidadeExposicao = ({ idPage, slugKey }) => {
  const { t } = useTranslation();
  const secaoId = t(slugKey);
  const { getPaginas, switchSizeImage } = blNewsService();
  const [model, setModel] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = async () => {
    await getPaginas(idPage)
      .then(({ items }) => (items && items.length ? items[0] : {}))
      .then((dado) => {
        setModel(dado);
        setIsLoading(true);
      });
  };

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? model.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <BannerInternas
        src={isLoading ? switchSizeImage(model.image, 'lg') : ''}
        alt={isLoading ? model.image_description : ''}
        className="mb-4 mt-30"
      />

      <Conteudo
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: isLoading ? model.content : '',
        }}
      />
    </Secao>
  );
};

SecaoCidadeExposicao.propTypes = {
  idPage: PropTypes.any,
  slugKey: PropTypes.string,
};

export default SecaoCidadeExposicao;
