import styled from 'styled-components';

export { default as BannerInternas } from '../../../components/BannerInternas';

export { Tradutor } from '../../../components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../../components/Secao';

export const Conteudo = styled.div``;
