import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Head from '../../../components/Head';
import blNewsService from '../../../services/blNewsService';
import {
  BotaoLink,
  Col,
  Conteudo,
  Imagem,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Tradutor,
  Video,
  // BotaoLinkExterno,
} from './styles';

const SecaoFeira = ({ idPage, ...props }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const secaoId = t('slugs.model');
  const { getPaginas } = blNewsService();
  const [model, setModel] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = async () => {
    await getPaginas(idPage)
      .then(({ items }) => (items && items.length ? items[0] : {}))
      .then((dado) => {
        setModel(dado);
        setIsLoading(true);
      });
  };

  const changeHead = () => (
    <Head title={model.title} description={model.description} />
  );

  return (
    <Secao {...props} id={secaoId}>
      {isLoading ? changeHead() : null}
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? model.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <SecaoSubTitulo className="mb-4">
        <Tradutor path={isLoading ? model.description : ''} />
      </SecaoSubTitulo>
      <Row>
        <Col md="6">
          <Conteudo
            dangerouslySetInnerHTML={{
              __html: isLoading ? model.content : '',
            }}
          />
          {/* <BotaoLinkExterno
            href="https://www.feirazerograu.com.br/2021/protocolo_feira_zerograu_2021.pdf"
            target="_blank"
            className="mb-30"
          >
            <Tradutor path="paginas.inicial.secaoSicc.botaoProtocolo" />
          </BotaoLinkExterno> */}
        </Col>
        {isLoading && (
          <Col md="6">
            {model.video ? (
              <Video
                imagemPlaceholder={isLoading ? model.image : ''}
                alt={isLoading ? model.image_description : ''}
                videoEmbedded={model.video}
                showIcon={true}
              />
            ) : (
              <Imagem
                src={isLoading ? model.image : ''}
                alt={isLoading ? model.image_description : ''}
              />
            )}
            <Row className="m-0">
              <Col className="pl-0">
                <BotaoLink
                  className="btn btn-primary btn-block"
                  to={`/${lang}#${t('slugs.queroVisitar')}`}
                >
                  <Tradutor path="paginas.inicial.secaoSicc.botaoQueroVisitar" />
                </BotaoLink>
              </Col>
              <Col className="pr-0">
                <BotaoLink
                  className="btn btn-primary btn-block"
                  to={`/${lang}/${t('slugs.galeriaDeImagens')}`}
                >
                  <Tradutor path="paginas.inicial.secaoSicc.botaoGaleria" />
                </BotaoLink>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Secao>
  );
};
SecaoFeira.propTypes = {
  idPage: PropTypes.any,
};
export default SecaoFeira;
