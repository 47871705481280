import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Itens, LinkPrincipalStyled, LinkStyled } from './styles';

const QueroVisitar = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Itens>
      <LinkPrincipalStyled to={`/${lang}#${t('slugs.queroVisitar')}`}>
        {t('cabecalho.queroVisitar')}
      </LinkPrincipalStyled>

      <LinkStyled
        target="_blank"
        to={
          '//merkatorhospedagem.bcseventos.inf.br/hospedagem/solicitacaoweb/login.php?id_edicao=26'
        }
      >
        {t('cabecalho.hospedagem')}
      </LinkStyled>
      <LinkStyled to={`/${lang}/${t('slugs.traslado')}`}>
        {t('cabecalho.traslado')}
      </LinkStyled>
      {/* <LinkStyled to={`/${lang}#${t('slugs.traslado')}`}>
        {t('cabecalho.credenciamento')}
      </LinkStyled> */}
      <LinkStyled to={`/${lang}#${t('slugs.agenciaDeTurismo')}`}>
        {t('cabecalho.agenciaDeTurismo')}
      </LinkStyled>
      <LinkStyled to={`/${lang}#${t('slugs.expositores')}`}>
        {t('cabecalho.expositores')}
      </LinkStyled>
      <LinkStyled to={`/${lang}#${t('slugs.plantaBaixa')}`}>
        {t('cabecalho.plantaBaixa')}
      </LinkStyled>
    </Itens>
  );
};

export default QueroVisitar;
