import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Head from '../../components/Head';
import Loader from '../../components/Loader';

import programacoesMock from '../../mocks/programacoes';
import api from '../../services/api';
import {
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenu,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoMenuNav,
  Tradutor,
  HashLink,
  FacebookIcon,
  FacebookShareButton,
  SecaoBotaoVoltar,
  SecaoBotaoVoltarProgramacao,
  ShareContainer,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  Conteudo,
  Titulo,
  ImageCover,
  SectionCenter,
  ProgramacaoImagemContainer,
  ProgramacaoConteudoContainer,
  ProgramacaoTitulo,
  ProgramacaoSubTitulo,
  ProgramacaoHorarioLocal,
  ProgramacaoConteudo,
  DiaSemana,
} from './styles';

const Programacao = () => {
  const { id } = useParams();
  const programacao = programacoesMock[0];
  const { t } = useTranslation();
  const { lang } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataPrograma, setDataPrograma] = useState();
  const [dataSpeaker, setDataSpeaker] = useState();

  useEffect(async () => {
    api.get(`programacaoevento/${id}`).then((response) => {
      const dateIni = new Date(response.data.data_ini.date);
      response.data.horario = dateIni;
      response.data.image = `http://paineldoexpositor.com.br/uploads/programacao/${response.data.image}`;
      setDataPrograma(response.data);
      setDataSpeaker(response.data.event_speaker);
      setIsLoading(true);
    });
  }, []);

  function nl2br(str, replaceMode, isXhtml) {
    var breakTag = isXhtml ? '<br />' : '<br>';
    var replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  }

  return (
    <section>
      <Head
        title={t('paginas.programacao.secaoProgramacao.titulo')}
        description={t('paginas.programacao.secaoProgramacao.titulo')}
      />

      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path="paginas.programacao.secaoProgramacao.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem />
              <SecaoMenuItem>
                <SecaoBotaoVoltarProgramacao />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>

      <div className="row">
        <div className="col-md-5">
          {isLoading && (
            <>
              <img
                src={dataPrograma.image}
                alt={dataPrograma.nome}
                width="100%"
              />
              <ShareContainer>
                {/* <FacebookShareButton url="http://localhost:3000/noticias/noticia">
                  <FacebookIcon />
                </FacebookShareButton>
                <TwitterShareButton url="http://localhost:3000/noticias/noticia">
                  <TwitterIcon />
                </TwitterShareButton>
                <WhatsappShareButton url="http://localhost:3000/noticias/noticia">
                  <WhatsappIcon />
                </WhatsappShareButton> */}
              </ShareContainer>
            </>
          )}
        </div>
        {!isLoading && (
          <SectionCenter>
            <Loader />
          </SectionCenter>
        )}
        {isLoading && (
          <div className="col-md-7">
            <Titulo>{dataPrograma.nome}</Titulo>
            <Titulo>
              {`${dataPrograma.horario.getDate()}/${
                dataPrograma.horario.getMonth() + 1
              }  `}
              {dataPrograma.horario.getHours()}h -{' '}
              {dataPrograma.categoria_evento.name}
            </Titulo>
            {dataPrograma.local && (
              <>
                <Titulo>{`Local: ${dataPrograma.local}`}</Titulo>
              </>
            )}
            <Conteudo
              dangerouslySetInnerHTML={{
                __html: dataPrograma.descricao,
              }}
            ></Conteudo>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          <DiaSemana>Palestrante(s)</DiaSemana>
          <div className="row">
            {isLoading &&
              dataSpeaker.map((speaker, key) => (
                <div
                  className="col-md-6"
                  style={{ marginBottom: 30 }}
                  key={key}
                >
                  <ProgramacaoImagemContainer>
                    <ImageCover
                      src={`http://paineldoexpositor.com.br/uploads/event_speaker/${speaker.image}`}
                    />
                  </ProgramacaoImagemContainer>
                  <ProgramacaoConteudoContainer>
                    <ProgramacaoTitulo>{speaker.name}</ProgramacaoTitulo>
                    <ProgramacaoConteudo
                      dangerouslySetInnerHTML={{
                        __html: `<p>${nl2br(
                          speaker.description,
                          true,
                          true
                        )}</p>`,
                      }}
                    ></ProgramacaoConteudo>
                  </ProgramacaoConteudoContainer>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Programacao;
