const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menu',
      sobreAFeira: 'about-a-feira',
      sicc: 'sicc',
      joaoPessoa: 'joao-pessoa',
      centroConvencoesJoaoPessoa: 'centro-de-convencoes-joao-pessoa',
      parceiros: 'partners',
      explosaoPremios: 'explosao-de-premios',
      estacao3: 'station-3',
      conectech: 'conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'hosting',
      traslado: 'transfer',
      credenciamento: 'accreditation',
      agenciaDeTurismo: 'agencia-de-turismo',
      expositores: 'exhibitors',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios: 'bolsas-e-acessorios',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'floor plan',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-talks',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'press',
      noticias: 'news',
      galeriaDeImagens: 'image-gallery',
      contato: 'contact',
      programacoes: 'schedules',
    },
    cabecalho: {
      painelExpositor: 'Exhibitor Panel',
      menu: 'Menu',
      sobreAFeira: 'About the Fair',
      sicc: 'SICC',
      joaoPessoa: 'João Pessoa',
      centroConvencoesJoaoPessoa: 'Centro de Convenções de João Pessoa',
      parceiros: 'Partners',
      explosaoPremios: 'Explosão de Prêmios',
      estacao3: 'Station 3',
      conectech: 'Conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'Accommodation',
      traslado: 'Transfer',
      credenciamento: 'Accreditation',
      agenciaDeTurismo: 'Tourism Agency',
      expositores: 'Exhibitors',
      plantaBaixa: 'Floor Plan',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'eMerkator Talks',
      eMerkatorBlog: 'eMerkator Blog',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Press',
      noticias: 'News',
      galeriaDeImagens: 'Image Gallery',
      contato: 'Contact',
    },
    paginas: {
      inicial: {
        secaoSicc: {
          titulo: 'SICC - International Leather and Footwear Exhibition',
          subTitulo: 'The new face of fashion',
          botaoQueroVisitar: 'I want to visit',
          botaoGaleria: 'Gallery',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Gramado',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Serra Park',
          subTitulo: 'Viação Férrea, nº 100, Gramado/RS',
          conteudo: '',
          tituloCaracteristicas: 'Features',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Partners',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Importers Group',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Station 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'I want to visit',
          hospedagem: {
            titulo: 'ACCOMMODATION',
            conteudo:
              '<p>Merkator Feiras e Eventos, promoter of 40 Graus, will provide accommodation in hotels in the city of João Pessoa/PB, during the fair period. The amenities are exclusive to retailers in the footwear sector, in the North and Northeast regions.</p>',
            textoBotao: 'Request courtesy here',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFER',
            conteudo:
              "<p>We will be operating with an internal transfer service from accredited hotels up to 40 Graus (João Pessoa Convention Center). Route schedules will be available at hotel receptions and also at the fair's accreditation.</p><p>João Pessoa hotels are near Presidente Castro Pinto International Airport. Upon arrival, use the taxi and/or transport options via the app for your convenience.</p>",
            textoBotao: 'Times and values here',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'ACCREDITATION',
            conteudo:
              '<p>Merkator wants to facilitate your entry into the fair.</p></>With this procedure, you will have greater comfort in issuing your fair access credential to learn about the main launches of the footwear and accessories industries in the country.</p>',
            textoBotao: 'Register here',
            subTextoBotao: 'Accreditation available soon',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Tourism agency',
          conteudo:
            "<p> Sunpower Turismo, & eacute; the fair's official travel agency. </p> <p> It has a team of qualified and experienced professionals, always providing a quality, personalized service, with a focus on customer satisfaction. </p> <p> We offer all travel logistic services with special discounts for the fair's participants / exhibitors. </p> <p> - Accommodation; <br /> - Passage to & eacute; area; <br /> - Car rental. </p> <p> We remind you that reservations requested in advance guarantee the best rates and conditions! </p> <p> More information & ccedil; & otilde; es, follow our contacts: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>",
          subTitulo: 'Official Tourism Agency',
        },
        secaoExpositores: {
          titulo: 'Exhibitors',
          subTitulo: 'Select the desired product:',
          subTituloBreve: 'Soon more information.',
        },
        secaoPlantaDaFeira: {
          titulo: 'Floor plan',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Connects you to the market',
          textoLinkProgramacao: 'Full schedule',
        },
        secaoImprensa: {
          titulo: 'Press',
          noticias: 'News',
          galeriaDeImagens: 'Image gallery',
          falarComImprensa: 'I want to speak to the press office',
        },
        secaoExplosaoPremios: {
          botao: 'Ganhadores',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'News',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'News',
          tituloNoticia:
            'Challenging year: promoter transits in virtual and in-person environments to hold events before and during the pandemic',
          legendaImagem:
            'Frederico at a press conference at Zero Grau 2020 - Photo: Dinarci Borges',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galleries',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'Schedules',
          diasSemana: {
            segunda: 'Monday',
            terca: 'Tuesday',
            quarta: 'Wednesday',
            quinta: 'Thursday',
            sexta: 'Friday',
            sabado: 'Saturday',
            domingo: 'Sunday',
          },
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'Schedule',
        },
      },
      traslado: {
        titulo: 'Transfer',
        conteudo:
          '<p>Estaremos operando com servi&ccedil;o de traslado interno dos hot&eacute;is credenciados at&eacute; a 40 Graus (Centro de Conven&ccedil;&otilde;es de Jo&atilde;o Pessoa). Os hor&aacute;rios das rotas estar&atilde;o dispon&iacute;veis nas recep&ccedil;&otilde;es dos hot&eacute;is e tamb&eacute;m no credenciamento da feira.</p><p>Os hot&eacute;is de Jo&atilde;o Pessoa s&atilde;o pr&oacute;ximos ao Aeroporto Internacional Presidente Castro Pinto. Ao chegar, utilize as op&ccedil;&otilde;es de t&aacute;xi e/ou transportes via aplicativo para sua maior comodidade.</p>',
      },
      explosaoPremios: {
        titulo: 'Explosão de Premios',
      },
      expositores: {
        titulo: 'Exhibitors',
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Page not found.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Email',
        senha: 'Password',
        entrar: 'Enter',
        cancelar: 'Cancel',
        esqueciMinhaSenha: 'I forgot my password',
      },
      secao: {
        botaoVoltar: 'Back',
      },
      cabecalho: {
        linguagem: 'Language',
        portugues: 'Portuguese',
        ingles: 'English',
        espanhol: 'Spanish',
      },
      rodape: {
        baixeNossoApp: 'Download our app',
        disponivelNa: 'Available in',
      },
      secaoEMerkator: {
        // programacaoCompleta: 'Full schedule',
        programacaoCompleta: 'Coming soon',
      },
    },
  },
};

export default traducoes;
