const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menú',
      sobreAFeira: 'sobre una feria',
      sicc: 'sicc',
      joaoPessoa: 'joao-pessoa',
      centroConvencoesJoaoPessoa: 'centro-de-convencoes-joao-pessoa',
      parceiros: 'socios',
      explosaoPremios: 'explosao-de-premios',
      estacao3: 'estacion-3',
      conectech: 'conectech',
      queroVisitar: 'Quiero Visitar',
      hospedagem: 'hospedaje',
      traslado: 'transferir',
      credenciamento: 'acreditación',
      agenciaDeTurismo: 'agencia de turismo',
      expositores: 'expositores',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios: 'bolsas-e-acessorios',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'Planta baja',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-charlas',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'Prensa',
      noticias: 'noticias',
      galeriaDeImagens: 'galeria-de-imagenes',
      contato: 'Contáctenos',
      programacoes: 'horarios',
    },
    cabecalho: {
      painelExpositor: 'Panel de expositores',
      menu: 'Menú',
      sobreAFeira: 'Sobre la Feria',
      sicc: 'SICC',
      joaoPessoa: 'João Pessoa',
      centroConvencoesJoaoPessoa: 'Centro de Convenções de João Pessoa',
      parceiros: 'Socios',
      explosaoPremios: 'La explosión de premio',
      estacao3: 'Estación 3',
      conectech: 'Conectech',
      queroVisitar: 'Quiero Visitar',
      hospedagem: 'Alojamiento',
      traslado: 'Transferir',
      credenciamento: 'Acreditación',
      agenciaDeTurismo: 'Agencia de Turismo',
      expositores: 'Expositores',
      plantaBaixa: 'Planta baja',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'Charlas de eMerkator',
      eMerkatorBlog: 'Blog de eMerkator',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Prensa',
      noticias: 'Noticias',
      galeriaDeImagens: 'Galería de Imágenes',
      contato: 'Contacto',
    },
    paginas: {
      inicial: {
        secaoSicc: {
          titulo: 'SICC - Salón Internacional del Cuero y Calzado',
          subTitulo: 'La nueva cara de la moda',
          botaoQueroVisitar: 'Quiero Visitar',
          botaoGaleria: 'Galería',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Césped',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Parque de la Serra',
          subTitulo: 'Viação Férrea, nº 100, Gramado / RS',
          conteudo: '',
          tituloCaracteristicas: 'Características',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Socios',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Grupo de importadores',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Estación 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'Quiero Visitar',
          hospedagem: {
            titulo: 'ALOJAMIENTO',
            conteudo:
              '<p>Merkator Feiras e Eventos, promotor de 40 Graus, proporcionará alojamiento en hoteles de la ciudad de João Pessoa / PB, durante el período ferial. Las amenidades son exclusivas para minoristas del sector del calzado, en las regiones Norte y Nordeste.</p>',
            textoBotao: 'Solicite cortesía aquí',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFERIR',
            conteudo:
              '<p>Estaremos operando con un servicio de traslado interno desde hoteles hasta 40 grados (Centro de Convenciones João Pessoa). Los horarios de ruta estarán disponibles en las recepciones de los hoteles y también en la acreditación de la feria.</p><p>Los hoteles João Pessoa están cerca del Aeropuerto Internacional Presidente Castro Pinto. A su llegada, utilice las opciones de taxi y / o transporte a través de la aplicación para su comodidad.</p>',
            textoBotao: 'Tiempos y valores aquí',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'Acreditación',
            conteudo:
              '<p>Merkator quiere facilitar su entrada a la feria.</p><p>Con este trámite, tendrá mayor comodidad al emitir su credencial de acceso justo para conocer los principales lanzamientos de las industrias de calzado y complementos en el país.</p>',
            textoBotao: 'Registrarse aquí',
            subTextoBotao: 'Acreditación disponible próximamente',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Agencia de turismo',
          conteudo:
            '<p> Sunpower Turismo, & eacute; agencia de viajes oficial de la feria. </p> <p> Cuenta con un equipo de profesionales calificados y con experiencia, brindando siempre un servicio personalizado de calidad, con un enfoque en la satisfacción del cliente. </p> <p> Ofrecemos todos los servicios de logística de viajes con descuentos especiales para los participantes / expositores de la feria. </p> <p> - Alojamiento; <br /> - Pasaje a & eacute; área; <br /> - Alquiler de coches. </p> <p> ¡Te recordamos que las reservas solicitadas con anticipación garantizan las mejores tarifas y condiciones! </p> <p> Más información & ccedil; & otilde; es, siga nuestros contactos: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>',
          subTitulo: 'Agencia Oficial de Turismo',
        },
        secaoExpositores: {
          titulo: 'Expositores',
          subTitulo: 'Seleccione el producto deseado:',
          subTituloBreve: 'En breve más información.',
        },
        secaoPlantaDaFeira: {
          titulo: 'Planta baja',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Te conecta con el mercado',
          textoLinkProgramacao: 'Agenda completa',
        },
        secaoImprensa: {
          titulo: 'Prensa',
          noticias: 'Noticias',
          galeriaDeImagens: 'Galería de imágenes',
          falarComImprensa: 'Quiero hablar con la oficina de prensa',
        },
        secaoExplosaoPremios: {
          botao: 'Ganhadores',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'Noticias',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'Noticias',
          tituloNoticia: '',
          legendaImagem: '',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galerías',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'Horarios',
          diasSemana: {
            segunda: 'lunes',
            terca: 'martes',
            quarta: 'miércoles',
            quinta: 'jueves',
            sexta: 'viernes',
            sabado: 'sábado',
            domingo: 'domingo',
          },
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'Calendario',
        },
      },
      traslado: {
        titulo: 'Transferir',
        conteudo:
          '<p>Estaremos operando com servi&ccedil;o de traslado interno dos hot&eacute;is credenciados at&eacute; a 40 Graus (Centro de Conven&ccedil;&otilde;es de Jo&atilde;o Pessoa). Os hor&aacute;rios das rotas estar&atilde;o dispon&iacute;veis nas recep&ccedil;&otilde;es dos hot&eacute;is e tamb&eacute;m no credenciamento da feira.</p><p>Os hot&eacute;is de Jo&atilde;o Pessoa s&atilde;o pr&oacute;ximos ao Aeroporto Internacional Presidente Castro Pinto. Ao chegar, utilize as op&ccedil;&otilde;es de t&aacute;xi e/ou transportes via aplicativo para sua maior comodidade.</p>',
      },
      explosaoPremios: {
        titulo: 'Explosão de Premios',
      },
      expositores: {
        titulo: 'Expositores',
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Página no encontrada.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Correo electrónico',
        senha: 'Contraseña',
        entrar: 'Enter',
        cancelar: 'Cancelar',
        esqueciMinhaSenha: 'Olvidé mi contraseña',
      },
      secao: {
        botaoVoltar: 'Regresar',
      },
      cabecalho: {
        linguagem: 'Idioma',
        portugues: 'portugués',
        ingles: 'inglés',
        espanhol: 'Español',
      },
      rodape: {
        baixeNossoApp: 'Descarga nuestra aplicación',
        disponivelNa: 'Disponible en',
      },
      secaoEMerkator: {
        programacaoCompleta: 'Pronto',
      },
    },
  },
};

export default traducoes;
