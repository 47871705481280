import React from 'react';

import SecaoImprensa from '@alweb-merkator/shared/pages/PaginaInicial/SecaoImprensa';
import SecaoEMerkator from '@alweb-merkator/shared/pages/PaginaInicial/SecaoEMerkator';
import SecaoPlantaDaFeira from '@alweb-merkator/shared/pages/PaginaInicial/SecaoPlantaDaFeira';
import SecaoExpositores from '@alweb-merkator/shared/pages/PaginaInicial/SecaoExpositoresEmBreve';
import SecaoAgenciaDeTurismo from '@alweb-merkator/shared/pages/PaginaInicial/SecaoAgenciaDeTurismo';
import SecaoParceiros from '@alweb-merkator/shared/pages/PaginaInicial/SecaoParceiros';
import AlertModal from '@alweb-merkator/shared/components/AlertModal';

import SecaoBanner from './SecaoBanner';
import SecaoFeira from './SecaoFeira';
import SecaoCidadeExposicao from './SecaoCidadeExposicao';
import SecaoLocalExposicao from './SecaoLocalExposicao';
import SecaoQueroVisitar from './SecaoQueroVisitar';
import SecaoExplosaoPremios from './SecaoExplosaoPremios';

const PaginaInicial = () => (
  <>
    <SecaoBanner />
    <SecaoFeira idPage={2} />
    <SecaoCidadeExposicao idPage={4} />
    <SecaoLocalExposicao idPage={3} />
    <SecaoParceiros idPage={18} />
    <SecaoExplosaoPremios idPage={463} />
    <SecaoQueroVisitar />
    <SecaoAgenciaDeTurismo idPage={15} />
    <SecaoExpositores />
    <SecaoPlantaDaFeira idPage={23} />
    <SecaoEMerkator contemProgramacao={false} />
    <SecaoImprensa />
    <AlertModal
      imageDescription="Banner de alerta sobre nova edição da feira"
      urlImage="#"
      urlTitle="Banner aviso nova edição da feira"
      imageModal="http://paineldoexpositor.com.br/uploads/media/md/media_62b5ff00962e45_94973574.jpeg"
      showModal={true}
    />
  </>
);

export default PaginaInicial;
