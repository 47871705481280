import React from 'react';
import PropTypes from 'prop-types';
import { Itens, LinkPrincipalStyled, LinkStyled } from './styles';

const SobreAFeira = ({ linksSobreAFeira }) => {
  return (
    <Itens>
      {linksSobreAFeira.map(({ text, link }, idx) => {
        if (idx === 0) {
          return (
            <LinkPrincipalStyled key={idx} to={link}>
              {text}
            </LinkPrincipalStyled>
          );
        } else {
          return (
            <LinkStyled key={idx} to={link}>
              {text}
            </LinkStyled>
          );
        }
      })}
    </Itens>
  );
};

SobreAFeira.propTypes = {
  linksSobreAFeira: PropTypes.array,
};

export default SobreAFeira;
