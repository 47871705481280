import { createGlobalStyle } from 'styled-components';
export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuItem,
  SecaoBotaoVoltar,
} from '@alweb-merkator/shared/components/Secao';
export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export const Styles = createGlobalStyle`
  .pagina-explosaoPremios {
    h1 {
      margin-bottom: 30px;
    }
    p {
      color: ${(props) => props.theme.colors.secondary};
      margin: 0;
    }
  }
`;
