import styled from 'styled-components';
import { Link } from 'react-router-dom';

import BannerInternas from '@alweb-merkator/shared/components/BannerInternas';

export { Col, Row } from 'reactstrap';

export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '@alweb-merkator/shared/components/Secao';

export const Conteudo = styled.div``;

export const ImagemResponsiva = styled(BannerInternas)`
  display: block;
  margin-left: auto;
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;

export const Botao = styled(Link).attrs((props) => ({
  className: `btn btn-primary ${props.className}`,
}))`
  display: inline-block;
  width: 85%;
  font-size: 30px;
  margin-bottom: 30px;
`;
