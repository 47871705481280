import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

export {
  Secao,
  SecaoTitulo,
  SecaoSubTitulo,
  SecaoBotaoVoltar,
  SecaoCabecalho,
} from '../../../components/Secao';

export { default as Video } from '../../../components/Video';

export { Col, Row } from 'reactstrap';

export { Tradutor } from '../../../components/I18n';

export const BotaoLink = styled(Link)`
  line-height: 2em;

  font-size: 1.3rem;

  @media (min-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const Conteudo = styled.div`
  @media (min-width: 768px) {
    padding-right: 10%;
  }

  @media (min-width: 992px) {
    padding-right: 15%;
  }

  @media (min-width: 1200px) {
    padding-right: 20%;
  }
`;

export const Imagem = styled.img`
  width: 100%;
  margin-bottom: 30px;
`;

export const BotaoLinkExterno = styled.a.attrs((props) => ({
  className: `btn btn-secondary ${props.className}`,
}))`
  display: inline-block;
  border-radius: 0.5rem;
  height: 65px;
  padding: 17.5px 30px;
  background-color: ${(props) => props.theme.colors.secundary};
`;
