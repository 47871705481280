/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import _ from 'lodash';

import api from '../../services/api';
import Loader from '../../components/Loader';
import Head from '../../components/Head';
import blNewsService from '../../services/blNewsService';

import { StoreContext } from '../../store';
import {
  Link,
  Secao,
  SecaoCabecalho,
  SecaoMenu,
  SecaoMenuLista,
  SecaoMenuNav,
  SecaoTitulo,
  Tradutor,
  SecaoBotaoVoltar,
  SecaoMenuItem,
  Galeria,
  SectionCenter,
} from './styles';

function Galerias() {
  const { t } = useTranslation();
  const { lang } = useParams();
  const { state, dispatch } = useContext(StoreContext);
  const [galeria, setGaleria] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState();
  const { switchSizeImage } = blNewsService();

  useEffect(() => {
    _handleRepository();
  }, []);

  const _handleRepository = async () => {
    let galeriasCarregadas = [];

    const carregarGalerias = async (pagina) => {
      const response = await api.get('/blnews?tipo=3&page=' + pagina);

      galeriasCarregadas = [
        ...galeriasCarregadas,
        ...response.data._embedded.post,
      ];

      if (response.data.page_count > response.data.page) {
        setGaleria(galeriasCarregadas);
        _handleArrayResult(galeriasCarregadas);
        await carregarGalerias(response.data.page + 1);
      }
    };

    await carregarGalerias(1);
  };

  function _handleArrayResult(data) {
    const arrAlbuns = [];
    const arrCategoria = [];
    data.forEach(function (ed) {
      arrCategoria.push(ed.bl_category_news[1]);
    });
    const uniqueCategory = _.uniqBy(
      [
        ...new Set(
          arrCategoria.map((data) => ({
            id: data.id,
            nome: data.nome,
          }))
        ),
      ],
      ({ id }) => id
    );

    uniqueCategory.forEach(function (cat, index) {
      const arrGaleria = [];
      data.forEach(function (data, idx) {
        if (cat.id === data.bl_category_news[1].id) {
          arrGaleria.push({
            titulo: data.title,
            id: data.id,
            imagem: data.image,
            slug: data.slug,
          });
        }
      });
      arrAlbuns.push({
        titulo: cat.nome,
        galerias: arrGaleria,
      });
    });
    setGallery(arrAlbuns);
    setIsLoading(true);
  }
  function renderGaleria(galeria, galeriaIdx) {
    const bgImage = switchSizeImage(galeria.imagem, 'sm');
    return (
      <Link
        key={`galeria-link-${galeriaIdx}`}
        className="galery_card"
        to={`/${lang}/${t('slugs.galeriaDeImagens')}/${galeria.slug}`}
      >
        <div
          className="galery_img"
          style={{ backgroundImage: `url(${bgImage})` }}
        />
        <div className="galery_overlay">
          <div className="galery_text">{galeria.titulo}</div>
        </div>
      </Link>
    );
  }

  function renderAlbum(album) {
    return (
      <div className="galery_container">
        <div className="galery_album_card">
          <div className="galery_text">{album.titulo}</div>
        </div>
        <div className="cards">
          {album.galerias.map((galeria, galeriaIdx) =>
            renderGaleria(galeria, galeriaIdx)
          )}
        </div>
      </div>
    );
  }

  return (
    <Secao className="mb-4">
      <Head
        title={t('paginas.galerias.titulo')}
        description={t('paginas.galerias.titulo')}
      />
      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path="paginas.galerias.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem>
                <SecaoBotaoVoltar />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>
      <Galeria>
        {isLoading &&
          gallery.map((album, albumIdx) => (
            <div key={albumIdx}>{renderAlbum(album)}</div>
          ))}
        {!isLoading && (
          <SectionCenter>
            <Loader />
          </SectionCenter>
        )}
      </Galeria>
    </Secao>
  );
}

export default Galerias;
