import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Itens, LinkPrincipalStyled, LinkStyled } from './styles';

const Imprensa = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Itens>
      <LinkPrincipalStyled to={`/${lang}#${t('slugs.eMerkator')}`}>
        {t('cabecalho.eMerkator')}
      </LinkPrincipalStyled>

      <LinkStyled to={`/${lang}/${t('slugs.noticias')}`}>
        {t('cabecalho.noticias')}
      </LinkStyled>
      <LinkStyled to={`/${lang}/${t('slugs.galeriaDeImagens')}`}>
        {t('cabecalho.galeriaDeImagens')}
      </LinkStyled>
    </Itens>
  );
};

export default Imprensa;
