import React from 'react';
import SecaoQueroVisitarShared from '@alweb-merkator/shared/pages/PaginaInicial/SecaoQueroVisitarOff';
import iconeCredenciamento from '../../../assets/icone-credenciamento.png';

const SecaoQueroVisitar = ({ ...props }) => {
  return (
    <SecaoQueroVisitarShared
      iconeCredenciamento={iconeCredenciamento}
      showTitle={true}
      {...props}
    />
  );
};

export default SecaoQueroVisitar;
