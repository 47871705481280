import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import { useParams } from 'react-router';

import Head from '../../components/Head';

import api from '../../services/api';

import {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Botao,
  Tradutor,
  GlobalStyles,
  SectionCenter,
  InputSearch,
} from './styles';

const Expositores = () => {
  const FEMININO = 'calcados-femininos';
  const MASCULINO = 'calcados-masculinos';
  const INFANTIL = 'calcados-infantis';
  const BOLSAS_E_ACESSORIOS = 'bolsas-acessorios-e-artefatos-de-couro';
  const ESPORTIVOS = 'calcados-esportivos';
  const LISTACOMPLETA = '';
  const EVENTO = 38;

  const [stand, setStand] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useParams();
  const [query, setQuery] = useState('');
  const [placeholder, setPlaceholder] = useState('Encontre a marca desejada');
  const [typeLocale, setTypeLocale] = useState('');
  const [labelLocation, setLabelLocation] = useState({
    corredor: 'Corredor',
    rua: 'Rua',
    produtos: 'Produtos',
  });
  const { categoria_slug: categoriaSlug } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    _handleGetEstandes();
    _translateInput(lang);
  }, []);

  const goToCategory = (category) => {
    setIsLoading(false);
    _handleRepository(category);
  };

  const _handleRepository = async (category) => {
    await api
      .get(`/estande?evento=${EVENTO}&produto=${category}`)
      .then((response) => {
        setStand(response.data._embedded.estande);
        setIsLoading(true);
      });
  };

  const _handleGetEstandes = async () => {
    await api
      .get(`/estande?evento=${EVENTO}&produto=${categoriaSlug || ''}`)
      .then((response) => {
        setStand(response.data._embedded.estande);
        setIsLoading(true);
      });
  };

  const showProduct = (products) => {
    const product = [];
    products.map((data, index) => product.push(data.name));
    return product.toString();
  };

  const _translateInput = (lang) => {
    switch (lang) {
      case 'en':
        setPlaceholder('Find the brand you want');
        setLabelLocation({
          corredor: 'Hall',
          rua: 'Street',
          produtos: 'Products',
        });
        break;
      case 'es':
        setPlaceholder('Encuentra la marca que quieres');
        setLabelLocation({
          corredor: 'Salón',
          rua: 'Calle',
          produtos: 'Productos',
        });
        break;
      default:
        setPlaceholder('Encontre a marca desejada');
        setLabelLocation({
          corredor: 'Corredor',
          rua: 'Rua',
          produtos: 'Produtos',
        });
    }
  };

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value.length >= 3) {
      setIsLoading(false);
      await api
        .get(`/estande?evento=${EVENTO}&nome=${query || ''}`)
        .then((response) => {
          setStand(response.data._embedded.estande);
          setIsLoading(true);
        });
    }
  };

  return (
    <>
      <GlobalStyles />

      <Head
        title={t('paginas.expositores.titulo')}
        description={t('paginas.expositores.titulo')}
      />

      <Secao className="mb-4 pagina-expositores">
        <SecaoCabecalho>
          <SecaoTitulo>
            <Tradutor path="paginas.expositores.titulo" />
          </SecaoTitulo>
          <SecaoBotaoVoltar />
        </SecaoCabecalho>
        <div className="row">
          <div className="col">
            <ul>
              <li>
                <InputSearch
                  value={query}
                  onChange={handleSearch}
                  placeholder={placeholder}
                />
              </li>
              <li>
                <Botao onClick={() => goToCategory(LISTACOMPLETA)}>
                  <Tradutor path="paginas.expositores.botaoCategoria.listaCompleta" />
                </Botao>
              </li>
              <li>
                <Botao onClick={() => goToCategory(FEMININO)}>
                  <Tradutor path="paginas.expositores.botaoCategoria.feminino" />
                </Botao>
              </li>
              <li>
                <Botao onClick={() => goToCategory(MASCULINO)}>
                  <Tradutor path="paginas.expositores.botaoCategoria.masculino" />
                </Botao>
              </li>
              <li>
                <Botao onClick={() => goToCategory(INFANTIL)}>
                  <Tradutor path="paginas.expositores.botaoCategoria.infantil" />
                </Botao>
              </li>
              <li>
                <Botao onClick={() => goToCategory(BOLSAS_E_ACESSORIOS)}>
                  <Tradutor path="paginas.expositores.botaoCategoria.acessorios" />
                </Botao>
              </li>
              <li>
                <Botao onClick={() => goToCategory(ESPORTIVOS)}>
                  <Tradutor path="paginas.expositores.botaoCategoria.esportivos" />
                </Botao>
              </li>
            </ul>
          </div>
          <div className="col">
            <div className=" expositores">
              {!isLoading && (
                <SectionCenter>
                  <Loader />
                </SectionCenter>
              )}
              <ul>
                {isLoading &&
                  stand.map((item, idx) => (
                    <li key={idx} className="expositor">
                      <p>{item.nome}</p>
                      <p>Corredor: {item.corredor}</p>
                      <p>Rua: {item.rua}</p>
                      <p>{item.tipo_local.name}</p>
                      <p>Produtos: {showProduct(item.produto_cliente)}</p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Secao>
    </>
  );
};

export default Expositores;
