import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import blNewsService from '@alweb-merkator/shared/services/blNewsService';

import {
  // Botao,
  Col,
  Conteudo,
  ImagemResponsiva,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Tradutor,
} from './styles';
// import { useParams } from 'react-router';

const SecaoExplosaoPremios = ({ idPage }) => {
  // const { lang } = useParams();
  const { t } = useTranslation();
  const secaoId = t('slugs.explosaoPremios');
  const { getPaginas } = blNewsService();
  const [importadores, setImportadores] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = async () => {
    await getPaginas(idPage)
      .then(({ items }) => (items && items.length ? items[0] : {}))
      .then((dado) => {
        setImportadores(dado);
        setIsLoading(true);
      });
  };

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4 mb-30">
        <SecaoTitulo>
          <Tradutor path={isLoading ? importadores.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <Row>
        <Col md="6">
          <Conteudo
            className="mb-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: t(isLoading ? importadores.content : ''),
            }}
          />
          {/* <Botao to={`/${lang}/${t('slugs.explosaoPremios')}`}>
            {t('paginas.inicial.secaoExplosaoPremios.botao')}
          </Botao> */}
        </Col>
        <Col md="6">
          <ImagemResponsiva
            className="m-height-200 m-negative-30"
            src={isLoading ? importadores.image : ''}
            alt={isLoading ? importadores.image_description : ''}
          />
        </Col>
      </Row>
    </Secao>
  );
};
SecaoExplosaoPremios.propTypes = {
  idPage: PropTypes.any,
  api: PropTypes.any,
};
export default SecaoExplosaoPremios;
