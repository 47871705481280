import styled from 'styled-components';
import BannerInternas from '../../../components/BannerInternas';

export { BannerInternas };

export { Col, Row } from 'reactstrap';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoSubTitulo,
} from '../../../components/Secao';

export const Conteudo = styled.div``;

export const TituloCaracteristicas = styled.h2``;

export const Mapa = styled(BannerInternas)``;

export const Iframe = styled.iframe.attrs(({ className = '' }) => ({
  className: `embed-responsive-item ${className}`,
}))`
  width: 100%;
  height: 600px;
`;
