import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #c5bac1;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const ListThumbnails = styled.ul`
  height: 100%;
`;

export const Thumbnail = styled.li`
  box-sizing: border-box;
  background: black;
  list-style: none;
  height: calc((100% - (15px * 6)) / 6);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.active {
    box-shadow: 0 0 15px 0 ${(props) => props.theme.colors.primary};
  }
`;

export const Image = styled.img`
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
`;
