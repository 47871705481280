import React from 'react';
import { useTranslation } from 'react-i18next';
import { Itens, ExternalLinkPrincipalStyled } from './styles';

const Contato = () => {
  const { t } = useTranslation();
  return (
    <Itens>
      <ExternalLinkPrincipalStyled
        href="http://merkatorfeiras.com.br/pt#contato"
        target="_blank"
      >
        {t('cabecalho.contato')}
      </ExternalLinkPrincipalStyled>
    </Itens>
  );
};

export default Contato;
