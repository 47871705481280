import React from 'react';
import SecaoLocalExposicaoShared from '@alweb-merkator/shared/pages/PaginaInicial/SecaoLocalExposicao';
import { useTranslation } from 'react-i18next';

function SecaoLocalExposicao(props) {
  const { t } = useTranslation();
  const googleMapsUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.4601487306177!2d-34.80679968522556!3d-7.188218894810245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7acc3c42511684d%3A0x409dda7a7828a078!2sCentro%20de%20Conven%C3%A7%C3%B5es%20de%20Jo%C3%A3o%20Pessoa!5e0!3m2!1spt-BR!2sbr!4v1619560813428!5m2!1spt-BR!2sbr';

  return (
    <SecaoLocalExposicaoShared
      slugKey={t('slugs.centroConvencoesJoaoPessoa')}
      fullMap
      googleMapsUrl={googleMapsUrl}
      {...props}
    />
  );
}

export default SecaoLocalExposicao;
