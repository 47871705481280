import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useParams } from 'react-router';
import LANGUAGES from '../../i18n/languages';

function Head({
  title = 'Merkator Feiras e Eventos',
  description = 'A Merkator Feiras e Eventos é a realizadora do SICC - Salão Internacional do Couro e do Calçado, da Zero Grau - Feira de Calçados e Acessórios, da 40 Graus - Feira de Calçados e Acessórios.',
  keywords = 'Merkator, Merkator Feiras, Merkator Feiras e Eventos, SICC, Zero Grau, 40 Graus, Feira Zero Graus, Feira 40 Graus, Salão Internacional do Couro e do Calçados',
  publishedTime = '2021-05-01T17:41:45+00:00',
  section = 'event',
  url = 'https://merkatorfeiras.com.br/',
  image = 'https://paineldoexpositor.com.br/uploads/media/media_60b68e5a63fe94_50770574.png',
  type = 'website',
  siteName = 'Merkator Feiras e Eventos',
}) {
  const { lang } = useParams();

  const locale = useMemo(() => {
    return LANGUAGES[lang].code;
  }, [lang]);

  function getUrl() {
    return window.location.href;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="article:published_time" content={publishedTime} />
      <meta property="article:section" content={section} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url || getUrl()} />
      <meta property="og:image" content={image} />
      <meta property="image" content={image} />
      <meta property="og:type" content={type} />
      <meta property="og:locale" content={locale} />
      <meta property="og:locale:alternate" content={locale} />
      <meta property="og:site_name" content={siteName} />
      <link rel="canonical" href={url || getUrl()} />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-38268862-1"
      ></script>
      <script>
        {`
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'UA-38268862-1');
        `}
      </script>
      <script>
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1260886231003593'); 
        fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        {`
        <img height="1" width="1" 
        src="https://www.facebook.com/tr?id=1260886231003593&ev=PageView
        &noscript=1"/>
        `}
      </noscript>
    </Helmet>
  );
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  publishedTime: PropTypes.string,
  section: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  siteName: PropTypes.string,
};

export default Head;
