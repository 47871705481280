import React from 'react';
import PropTypes from 'prop-types';
import { Picture, Imagem } from './styles';

function ImageCover({ src = '', style = {}, className = '' }) {
  return (
    <Picture
      className={`${className}`}
      style={{ backgroundImage: `url(${src})`, ...style }}
    >
      <Imagem src={src} />
    </Picture>
  );
}

ImageCover.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};

export default ImageCover;
