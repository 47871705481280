import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

export const Secao = styled.section`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 70px !important;
  }
`;

export const SecaoCabecalho = styled.div`
  width: 100%;
  height: auto;

  &:after {
    content: '';
    clear: both;
    display: table;
  }
`;

export const SecaoTitulo = styled.h1`
  padding: 0;
  margin: 0;
  margin-right: 15px;
  height: 100%;
  float: left;
  color: ${(props) => props.theme.colors.primary};
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const SecaoSubTitulo = styled.h2`
  font-weight: normal;
  font-style: italic;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.5rem;
`;

export const SecaoMenu = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: auto;
    overflow: hidden;
    height: 3rem;
    border-width: 1px 0 1px 0;
    border-color: #000;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.primary};
    font-style: italic;
  }
`;

export const SecaoMenuNav = styled.nav`
  width: 100%;
`;

export const SecaoMenuLista = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SecaoMenuItem = styled.li`
  color: ${(props) => props.theme.colors.primary};
  line-height: 2.5rem;
  font-size: 1rem;
`;

export const LinkVoltar = styled(HashLink)`
  display: none;
  font-size: 1rem;
  line-height: 2.5rem;

  @media (min-width: 768px) {
    width: auto;
    overflow: hidden;
    display: block;
    min-width: 90px;
    text-align: right;
    cursor: pointer;
    font-style: italic;
  }
`;
