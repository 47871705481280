import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import Banner from '../../../components/Banner';
import { SecaoCabecalho as CompSecaoCabecalho } from '../../../components/Secao';

export { Col, Row } from 'reactstrap';
export {
  Secao,
  SecaoBotaoVoltar,
  SecaoTitulo,
} from '../../../components/Secao';

export const SecaoCabecalho = styled(CompSecaoCabecalho)`
  margin-bottom: 4rem;
`;

export const Botao = styled(Link).attrs(({ className }) => ({
  className: `btn btn-primary w-100 ${className}`,
}))`
  display: block;
  font-size: 1.5rem;
  margin-bottom: 15px;
  padding: 1rem 0;
`;

export const Conteudo = styled.div``;

export const ImagemResponsiva = styled(Banner)`
  display: block;
  margin-left: auto;
`;

export const Box = styled.div`
  text-align: right;
  position: absolute;
  display: inline-block;
  top: calc(50% - 88.5px);
  right: 15px;
  -ms-transform: translate(0, calc(-50% + 88.5px));
  transform: translate(0, calc(-50% + 88.5px));
`;

export const TextoAgenciaTurismoOficial = styled.span`
  font-style: italic;
`;
