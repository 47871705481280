import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Thumbnails, Slider } from './styles';

function ImageGallery({ items = [] }) {
  const [activeItem, setActiveItem] = useState(0);
  function changeActiveItem(idx) {
    setActiveItem(idx);
  }

  return (
    <>
      <Container>
        <Slider
          items={items}
          activeItem={activeItem}
          changeActiveItem={changeActiveItem}
        />
        <Thumbnails
          items={items}
          activeItem={activeItem}
          changeActiveItem={changeActiveItem}
        />
      </Container>
    </>
  );
}

ImageGallery.propTypes = {
  items: PropTypes.array,
};

export default ImageGallery;
