import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import api from '../../services/api';
import { format } from 'date-fns';

import Head from '../../components/Head';
import Loader from '../../components/Loader';
import blNewsService from '../../services/blNewsService';

import {
  Botao,
  Secao,
  SecaoCabecalho,
  SecaoMenu,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoMenuNav,
  SecaoTitulo,
  Tradutor,
  SecaoBotaoVoltar,
  HashLink,
  Link,
  ImageCover,
  Programacao,
  ProgramacaoImagemContainer,
  ProgramacaoConteudoContainer,
  ProgramacaoTitulo,
  ProgramacaoSpeaker,
  ProgramacaoHorarioLocal,
  ProgramacaoConteudo,
  DiaSemana,
  PlusIcon,
  SectionCenter,
} from './styles';

function Programacoes() {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [schedule, setSchedule] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [weekDayschedule, setWeekDayschedule] = useState();
  const { weekDay, convertToSlug } = blNewsService();

  useEffect(() => {
    _handleSchedule();
  }, []);

  const _handleSchedule = async () => {
    await api.get(`programacaoevento?evento=37`).then((response) => {
      response.data._embedded.programacao_evento.forEach(function (e, key) {
        const dateIni = new Date(e.data_ini.date.split(' ').join('T'));
        response.data._embedded.programacao_evento[key].data_ini = dateIni;
        response.data._embedded.programacao_evento[
          key
        ].dia_semana = dateIni.getDay();
      });
      setSchedule(response.data._embedded.programacao_evento);
      setWeekDayschedule(
        _.uniqBy(
          [
            ...new Set(
              response.data._embedded.programacao_evento.map((data) => ({
                weekDay: data.dia_semana,
                date_ini: data.data_ini,
              }))
            ),
          ],
          ({ weekDay }) => weekDay
        )
      );
      setIsLoading(true);
    });
  };

  function getProgramacoesSemana(semana) {
    return schedule.filter(
      (programacao) => programacao.dia_semana === semana.weekDay
    );
  }

  return (
    <Secao className="mb-4">
      <Head
        title={t('paginas.programacoes.secaoProgramacoes.titulo')}
        description={t('paginas.programacoes.secaoProgramacoes.titulo')}
      />
      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path="paginas.programacoes.secaoProgramacoes.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              {isLoading &&
                weekDayschedule.map((dataDay, key) => (
                  <>
                    <HashLink
                      to={`/${lang}/${t('slugs.programacoes')}#${
                        dataDay.weekDay
                      }`}
                    >
                      <SecaoMenuItem>
                        {t(
                          `paginas.programacoes.secaoProgramacoes.diasSemana.${dataDay.weekDay}`
                        )}
                        {` ${dataDay.date_ini.getDate()}/${
                          dataDay.date_ini.getMonth() + 1
                        }`}
                      </SecaoMenuItem>
                    </HashLink>
                  </>
                ))}
              <SecaoMenuItem>
                <SecaoBotaoVoltar />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>
      {!isLoading && (
        <SectionCenter>
          <Loader />
        </SectionCenter>
      )}
      {isLoading &&
        weekDayschedule.map((semanaProgramacoes, spKey) => (
          <div key={spKey} id={semanaProgramacoes.weekDay}>
            <DiaSemana>
              {t(
                `paginas.programacoes.secaoProgramacoes.diasSemana.${semanaProgramacoes.weekDay}`
              )}
              {` ${semanaProgramacoes.date_ini.getDate()}/${
                semanaProgramacoes.date_ini.getMonth() + 1
              }`}
            </DiaSemana>
            <div className="row">
              {getProgramacoesSemana(semanaProgramacoes).map(
                (programacao, pKey) => (
                  <div
                    className="col-md-6"
                    style={{ marginBottom: 30 }}
                    key={pKey}
                  >
                    <Link
                      to={`/${lang}/${t('slugs.programacoes')}/${
                        programacao.id
                      }/${convertToSlug(programacao.nome)}`}
                    >
                      <Programacao>
                        <ProgramacaoImagemContainer>
                          <ImageCover
                            src={`http://paineldoexpositor.com.br/uploads/programacao/${programacao.image}`}
                          />
                          <Botao>
                            <PlusIcon />
                          </Botao>
                        </ProgramacaoImagemContainer>
                        <ProgramacaoConteudoContainer>
                          <ProgramacaoTitulo>
                            {programacao.nome}
                          </ProgramacaoTitulo>
                          {programacao.event_speaker.map((speaker, key) => (
                            <>
                              <ProgramacaoSpeaker>
                                {speaker.name}
                              </ProgramacaoSpeaker>
                            </>
                          ))}
                          <ProgramacaoHorarioLocal>
                            {`${programacao.data_ini.getHours()}:${String(
                              programacao.data_ini.getMinutes()
                            ).padStart(2, '0')}`}
                            h - {programacao._embedded.categoria_evento.nome}
                          </ProgramacaoHorarioLocal>
                          {programacao.local && (
                            <>
                              <ProgramacaoHorarioLocal>
                                {`Local: ${programacao.local}`}
                              </ProgramacaoHorarioLocal>
                            </>
                          )}
                          <ProgramacaoConteudo
                            dangerouslySetInnerHTML={{
                              __html: programacao.descricao.substring(0, 1000),
                            }}
                          ></ProgramacaoConteudo>
                        </ProgramacaoConteudoContainer>
                      </Programacao>
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
    </Secao>
  );
}

export default Programacoes;
