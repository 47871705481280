import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.merkator.alweb.com.br',
  headers: { Authorization: 'Basic bWVya2F0b3I6RkQuc2pha2llMTI3MTIw' },
});

api.interceptors.request.use((request) => {
  if (!request.params) {
    request.params = { categoria: null };
  }

  if (!request.params.categoria) {
    request.params.categoria = localStorage.getItem('SITE_ID');
  }

  return request;
});

export default api;
