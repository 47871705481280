import React from 'react';
import PropTypes from 'prop-types';
import {
  Botao,
  FacebookIcon,
  AppleIcon,
  InstagramIcon,
  GooglePlayIcon,
} from './styles';

const Midia = ({ socialMedia, link }) => {
  let Icon = null;

  switch (socialMedia) {
    case 'facebook':
      Icon = FacebookIcon;
      break;
    case 'instagram':
      Icon = InstagramIcon;
      break;
    case 'apple':
      Icon = AppleIcon;
      break;
    case 'googlePlay':
      Icon = GooglePlayIcon;
      break;
    default:
      break;
  }

  return (
    <Botao target="_blank" to={link}>
      {Icon ? <Icon /> : null}
    </Botao>
  );
};

Midia.propTypes = {
  socialMedia: PropTypes.string,
  link: PropTypes.string,
};

const Midias = ({ midias = [], ...props }) => (
  <div {...props}>
    {midias.map((midiaProps, key) => (
      <Midia key={key} {...midiaProps} />
    ))}
  </div>
);

Midias.propTypes = {
  midias: PropTypes.array,
};

export default Midias;
