import ptBrTraducoes from './pt-br';
import enUsTraducoes from './en';
import esTraducoes from './es';

const traducoes = {
  'pt-BR': ptBrTraducoes,
  en: enUsTraducoes,
  es: esTraducoes,
};

export default traducoes;
