import React from 'react';
import PropTypes from 'prop-types';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import Aplicativos from './Aplicativos';
import Contato from './Contato';
import EMerkator from './EMerkator';
import Imprensa from './Imprensa';
import QueroVisitar from './QueroVisitar';
import SobreAFeira from './SobreAFeira';
import { Container, FooterStyled } from './styles';

const RodapeFeiras = ({ linksSobreAFeira, medias }) => {
  const aplicativos = [
    {
      Icone: FaApple,
      textoNomeLoja: 'App Store',
      linkApp: medias.appStore,
    },
    {
      Icone: FaGooglePlay,
      textoNomeLoja: 'Google Play',
      linkApp: medias.googlePlay,
    },
  ];

  return (
    <FooterStyled>
      <Container fluid>
        <div className="row">
          <div className="col-md-7 seven-three">
            <div className="row">
              <div className="col-md-4">
                <SobreAFeira linksSobreAFeira={linksSobreAFeira} />
              </div>
              <div className="col-md-4">
                <QueroVisitar />
              </div>
              <div className="col-md-4">
                <EMerkator />
              </div>
            </div>
          </div>
          <div className="col-md-5 five-two">
            <div className="row">
              <div className="col-md-6">
                <Imprensa />
                <Contato />
              </div>
              <div className="col-md-6">
                <Aplicativos itens={aplicativos} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </FooterStyled>
  );
};

RodapeFeiras.propTypes = {
  linksSobreAFeira: PropTypes.array,
  medias: PropTypes.any,
};

export default RodapeFeiras;
