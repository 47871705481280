import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from './api';

const blNewsService = () => {
  const { lang } = useParams();

  const tratarPost = useCallback(
    (dados) => {
      const dado = {
        title: '',
        content: '',
        description: '',
        slug: dados.slug,
        image: dados.image,
        video: dados.video,
        keyword: dados.keyword,
        image_description: dados.image_description,
      };

      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
        dado.description = dados['description_' + lang];
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
        dado.description = dados.description;
      }

      return dado;
    },
    [lang]
  );

  const tratarDadosBlNews = useCallback(
    (data) => {
      const newData = {
        items: [],
        page_count: data.page_count || 1,
        page_size: data.page_size || 1,
        total_items: data.total_items || 1,
        page: data.page || 1,
      };

      if (data._embedded && data._embedded.post) {
        newData.items = data._embedded.post.map(tratarPost);
      } else if (data[0]) {
        const item = tratarPost(data[0]);
        newData.items.push(item);
      }

      return newData;
    },
    [tratarPost]
  );

  const getPaginas = useCallback(
    (id, pagina = 1) =>
      new Promise((resolve, reject) => {
        api
          .get(`/blnews${id ? '/' + id : ''}`, {
            params: {
              tipo: 1,
              page: pagina,
            },
          })
          .then((response) => resolve(tratarDadosBlNews(response.data)))
          .catch(reject);
      }),
    [tratarDadosBlNews]
  );

  const getNoticias = useCallback(
    (id, pagina = 1) =>
      new Promise((resolve, reject) => {
        api
          .get(`/blnews${id ? '/' + id : ''}`, {
            params: {
              tipo: 2,
              page: pagina,
            },
          })
          .then((response) => resolve(tratarDadosBlNews(response.data)))
          .catch(reject);
      }),
    [tratarDadosBlNews]
  );

  const getGalerias = useCallback(
    (id, pagina = 1) =>
      new Promise((resolve, reject) => {
        api
          .get(`/blnews${id ? '/' + id : ''}`, {
            params: {
              tipo: 3,
              page: pagina,
            },
          })
          .then((response) => resolve(tratarDadosBlNews(response.data)))
          .catch(reject);
      }),
    [tratarDadosBlNews]
  );

  const getVideos = useCallback(
    (id, pagina = 1) =>
      new Promise((resolve, reject) => {
        api
          .get(`/blnews${id ? '/' + id : ''}`, {
            params: {
              tipo: 4,
              page: pagina,
            },
          })
          .then((response) => resolve(tratarDadosBlNews(response.data)))
          .catch(reject);
      }),
    [tratarDadosBlNews]
  );

  const switchSizeImage = useCallback((image, size) => {
    const separateString = image.split('/uploads/media/');
    return (
      separateString[0] + '/uploads/media/' + size + '/' + separateString[1]
    );
  });

  const weekDay = useCallback((day) => {
    const WeekDays = [
      'Domingo',
      'Segunda-Feira',
      'Terça-Feira',
      'Quarta-Feira',
      'Quinta-Feira',
      'Sexta-Feira',
      'Sábado',
    ];

    return WeekDays[day];
  });

  const convertToSlug = useCallback((Text) => {
    return Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  });

  return {
    getPaginas,
    getNoticias,
    getGalerias,
    getVideos,
    switchSizeImage,
    weekDay,
    convertToSlug,
  };
};

export default blNewsService;
