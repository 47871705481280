import { Modal as RsModal, ModalBody as RsModalBody } from 'reactstrap';

import styled from 'styled-components';

import { MdPlayCircleFilled } from 'react-icons/md';

export const VideoStyled = styled.div`
  margin-bottom: 30px;
`;

export const ImagemComOverlay = styled.div`
  position: relative;
`;

export const ImagemPlaceholder = styled.img`
  width: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: 0.5s ease;
`;

export const IconOverlay = styled(MdPlayCircleFilled)`
  opacity: 0.5;
  position: absolute;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.colors.primary};
  font-size: 200px;
`;

export const Modal = styled(RsModal)``;

export const ModalBody = styled(RsModalBody).attrs(({ className = '' }) => ({
  className: `mb-0 p-0 ${className}`,
}))``;

export const Embed = styled.div.attrs(({ className = '' }) => ({
  className: `embed-responsive embed-responsive-16by9 z-depth-1-half ${className}`,
}))``;

export const Iframe = styled.iframe.attrs(({ className = '' }) => ({
  className: `embed-responsive-item ${className}`,
}))``;
