import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Head from '../../components/Head';
import blNewsService from '../../services/blNewsService';

import api from '../../services/api';
import {
  Secao,
  Col,
  ImagemResponsiva,
  NoticiaTitulo,
  Row,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoMenu,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoMenuNav,
  SecaoTitulo,
  Tradutor,
  Conteudo,
  LegendaImagem,
  Tags,
  Tag,
  TextoTags,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  ShareContainer,
} from './styles';


function Noticia() {
  const { noticiaSlug, lang } = useParams();
  const [postView, setPostView] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tagNews, setTagNews] = useState('');
  const { switchSizeImage } = blNewsService();

  const { t } = useTranslation();

  useEffect(() => {
    handlePostView();
    return function cleanup() {};
  }, []);

  const handlePostView = async () => {
    await api.get(`blnews/${noticiaSlug}`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: dados.title,
        description: dados.description,
        content: dados.content,
        image: dados.image,
        keyword: dados.keyword,
        image_description: dados.image_description,
      };

      setPostView(dado);
      handleSetTags(dado.keyword);
      setIsLoading(true);
    });
  };

  const handleSetTags = (keywords) => {
    if (keywords !== null) {
      if (keywords.length > 0) {
        console.log(keywords);
        setTagNews(keywords.split(','));
      }
    }
  };

  const changeHead = () => (
    <Head
      title={postView.title}
      description={postView.description}
      image={postView.image}
    />
  );

  return (
    <Secao className="mb-4">
      {isLoading ? changeHead() : null}
      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path="paginas.noticia.secaoNoticia.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem>home</SecaoMenuItem>
              <SecaoMenuItem>
                <SecaoBotaoVoltar />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>

      <Row>
        <Col md="12">
          <ShareContainer>
            <FacebookShareButton
              quote={postView.title}
              url={window.location.href}
            >
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton
              url={window.location.href}
              title={postView.title}
              caption={postView.description}
            >
              <TwitterIcon />
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon />
            </WhatsappShareButton>
          </ShareContainer>
        </Col>
        <Col md="4">
          {postView.image && (
            <ImagemResponsiva
              src={switchSizeImage(postView.image, 'md')}
              alt={postView.image_description}
            />
          )}
          <LegendaImagem>
            <Tradutor path={postView.image_description} />
          </LegendaImagem>
        </Col>
        <Col md="8">
          <NoticiaTitulo>{postView.title}</NoticiaTitulo>
          {isLoading && (
            <Conteudo
              dangerouslySetInnerHTML={{
                __html: postView.content,
              }}
            />
          )}
          {tagNews.length > 0 && (
            <Tags>
              <TextoTags>
                {`${t('paginas.noticia.secaoNoticia.textoTags')}:`}
              </TextoTags>
              {tagNews.map((tag, idx) => (
                <Tag key={idx}>{tag}</Tag>
              ))}
            </Tags>
          )}
        </Col>
      </Row>
    </Secao>
  );
}

export default Noticia;
